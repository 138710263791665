/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class InferenceType.
* @enum {}
* @readonly
*/
export default class InferenceType {
    
        /**
         * value: "NOT_CONFIGURED"
         * @const
         */
        "NOT_CONFIGURED" = "NOT_CONFIGURED";

    
        /**
         * value: "CLASSIFICATION"
         * @const
         */
        "CLASSIFICATION" = "CLASSIFICATION";

    
        /**
         * value: "OBJECT_DETECTION"
         * @const
         */
        "OBJECT_DETECTION" = "OBJECT_DETECTION";

    
        /**
         * value: "SEGMENTATION"
         * @const
         */
        "SEGMENTATION" = "SEGMENTATION";

    
        /**
         * value: "POINT"
         * @const
         */
        "POINT" = "POINT";

    
        /**
         * value: "LINE"
         * @const
         */
        "LINE" = "LINE";

    
        /**
         * value: "MULTI"
         * @const
         */
        "MULTI" = "MULTI";

    

    /**
    * Returns a <code>InferenceType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/InferenceType} The enum <code>InferenceType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

