/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import InferenceType from './InferenceType';
import LabelOut from './LabelOut';
import PicturedUserOut from './PicturedUserOut';

/**
 * The DatasetVersionOut model module.
 * @module model/DatasetVersionOut
 * @version 1.0.0
 */
class DatasetVersionOut {
    /**
     * Constructs a new <code>DatasetVersionOut</code>.
     * @alias module:model/DatasetVersionOut
     * @param lockedAt {Date} 
     * @param isLocked {Boolean} 
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param id {String} 
     * @param version {String} 
     * @param description {String} 
     * @param originId {String} 
     * @param originName {String} 
     * @param type {module:model/InferenceType} 
     * @param labels {Array.<module:model/LabelOut>} 
     */
    constructor(lockedAt, isLocked, createdAt, updatedAt, createdBy, id, version, description, originId, originName, type, labels) { 
        
        DatasetVersionOut.initialize(this, lockedAt, isLocked, createdAt, updatedAt, createdBy, id, version, description, originId, originName, type, labels);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, lockedAt, isLocked, createdAt, updatedAt, createdBy, id, version, description, originId, originName, type, labels) { 
        obj['locked_at'] = lockedAt;
        obj['is_locked'] = isLocked;
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['id'] = id;
        obj['version'] = version;
        obj['description'] = description;
        obj['origin_id'] = originId;
        obj['origin_name'] = originName;
        obj['type'] = type;
        obj['labels'] = labels;
    }

    /**
     * Constructs a <code>DatasetVersionOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DatasetVersionOut} obj Optional instance to populate.
     * @return {module:model/DatasetVersionOut} The populated <code>DatasetVersionOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DatasetVersionOut();

            if (data.hasOwnProperty('locked_by')) {
                obj['locked_by'] = ApiClient.convertToType(data['locked_by'], 'String');
            }
            if (data.hasOwnProperty('locked_at')) {
                obj['locked_at'] = ApiClient.convertToType(data['locked_at'], 'Date');
            }
            if (data.hasOwnProperty('is_locked')) {
                obj['is_locked'] = ApiClient.convertToType(data['is_locked'], 'Boolean');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('origin_id')) {
                obj['origin_id'] = ApiClient.convertToType(data['origin_id'], 'String');
            }
            if (data.hasOwnProperty('origin_name')) {
                obj['origin_name'] = ApiClient.convertToType(data['origin_name'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = InferenceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('labels')) {
                obj['labels'] = ApiClient.convertToType(data['labels'], [LabelOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DatasetVersionOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DatasetVersionOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DatasetVersionOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['locked_by'] && !(typeof data['locked_by'] === 'string' || data['locked_by'] instanceof String)) {
            throw new Error("Expected the field `locked_by` to be a primitive type in the JSON string but got " + data['locked_by']);
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['version'] && !(typeof data['version'] === 'string' || data['version'] instanceof String)) {
            throw new Error("Expected the field `version` to be a primitive type in the JSON string but got " + data['version']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['origin_id'] && !(typeof data['origin_id'] === 'string' || data['origin_id'] instanceof String)) {
            throw new Error("Expected the field `origin_id` to be a primitive type in the JSON string but got " + data['origin_id']);
        }
        // ensure the json data is a string
        if (data['origin_name'] && !(typeof data['origin_name'] === 'string' || data['origin_name'] instanceof String)) {
            throw new Error("Expected the field `origin_name` to be a primitive type in the JSON string but got " + data['origin_name']);
        }
        if (data['labels']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['labels'])) {
                throw new Error("Expected the field `labels` to be an array in the JSON data but got " + data['labels']);
            }
            // validate the optional field `labels` (array)
            for (const item of data['labels']) {
                LabelOut.validateJSON(item);
            };
        }

        return true;
    }


}

DatasetVersionOut.RequiredProperties = ["locked_at", "is_locked", "created_at", "updated_at", "created_by", "id", "version", "description", "origin_id", "origin_name", "type", "labels"];

/**
 * @member {String} locked_by
 */
DatasetVersionOut.prototype['locked_by'] = undefined;

/**
 * @member {Date} locked_at
 */
DatasetVersionOut.prototype['locked_at'] = undefined;

/**
 * @member {Boolean} is_locked
 */
DatasetVersionOut.prototype['is_locked'] = undefined;

/**
 * @member {Date} created_at
 */
DatasetVersionOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
DatasetVersionOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
DatasetVersionOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
DatasetVersionOut.prototype['id'] = undefined;

/**
 * @member {String} version
 */
DatasetVersionOut.prototype['version'] = undefined;

/**
 * @member {String} description
 */
DatasetVersionOut.prototype['description'] = undefined;

/**
 * @member {String} origin_id
 */
DatasetVersionOut.prototype['origin_id'] = undefined;

/**
 * @member {String} origin_name
 */
DatasetVersionOut.prototype['origin_name'] = undefined;

/**
 * @member {module:model/InferenceType} type
 */
DatasetVersionOut.prototype['type'] = undefined;

/**
 * @member {Array.<module:model/LabelOut>} labels
 */
DatasetVersionOut.prototype['labels'] = undefined;






export default DatasetVersionOut;

