/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorOut from './CollaboratorOut';
import PicturedUserOut from './PicturedUserOut';

/**
 * The ExtendedProjectOut model module.
 * @module model/ExtendedProjectOut
 * @version 1.0.0
 */
class ExtendedProjectOut {
    /**
     * Constructs a new <code>ExtendedProjectOut</code>.
     * @alias module:model/ExtendedProjectOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param id {String} 
     * @param name {String} 
     * @param description {String} 
     * @param nbExperiments {Number} 
     * @param nbDatasets {Number} 
     * @param collaborators {Array.<module:model/CollaboratorOut>} 
     */
    constructor(createdAt, updatedAt, createdBy, id, name, description, nbExperiments, nbDatasets, collaborators) { 
        
        ExtendedProjectOut.initialize(this, createdAt, updatedAt, createdBy, id, name, description, nbExperiments, nbDatasets, collaborators);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, id, name, description, nbExperiments, nbDatasets, collaborators) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['id'] = id;
        obj['name'] = name;
        obj['description'] = description;
        obj['nb_experiments'] = nbExperiments;
        obj['nb_datasets'] = nbDatasets;
        obj['collaborators'] = collaborators;
    }

    /**
     * Constructs a <code>ExtendedProjectOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedProjectOut} obj Optional instance to populate.
     * @return {module:model/ExtendedProjectOut} The populated <code>ExtendedProjectOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedProjectOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('nb_experiments')) {
                obj['nb_experiments'] = ApiClient.convertToType(data['nb_experiments'], 'Number');
            }
            if (data.hasOwnProperty('nb_datasets')) {
                obj['nb_datasets'] = ApiClient.convertToType(data['nb_datasets'], 'Number');
            }
            if (data.hasOwnProperty('collaborators')) {
                obj['collaborators'] = ApiClient.convertToType(data['collaborators'], [CollaboratorOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedProjectOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedProjectOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedProjectOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        if (data['collaborators']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['collaborators'])) {
                throw new Error("Expected the field `collaborators` to be an array in the JSON data but got " + data['collaborators']);
            }
            // validate the optional field `collaborators` (array)
            for (const item of data['collaborators']) {
                CollaboratorOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExtendedProjectOut.RequiredProperties = ["created_at", "updated_at", "created_by", "id", "name", "description", "nb_experiments", "nb_datasets", "collaborators"];

/**
 * @member {Date} created_at
 */
ExtendedProjectOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedProjectOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ExtendedProjectOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedProjectOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ExtendedProjectOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ExtendedProjectOut.prototype['description'] = undefined;

/**
 * @member {Number} nb_experiments
 */
ExtendedProjectOut.prototype['nb_experiments'] = undefined;

/**
 * @member {Number} nb_datasets
 */
ExtendedProjectOut.prototype['nb_datasets'] = undefined;

/**
 * @member {Array.<module:model/CollaboratorOut>} collaborators
 */
ExtendedProjectOut.prototype['collaborators'] = undefined;






export default ExtendedProjectOut;

