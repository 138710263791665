/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorRole from './CollaboratorRole';

/**
 * The CollaboratorOut model module.
 * @module model/CollaboratorOut
 * @version 1.0.0
 */
class CollaboratorOut {
    /**
     * Constructs a new <code>CollaboratorOut</code>.
     * @alias module:model/CollaboratorOut
     * @param id {String} 
     * @param isActive {Boolean} 
     * @param role {module:model/CollaboratorRole} 
     * @param userId {String} 
     * @param username {String} 
     * @param email {String} 
     */
    constructor(id, isActive, role, userId, username, email) { 
        
        CollaboratorOut.initialize(this, id, isActive, role, userId, username, email);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, isActive, role, userId, username, email) { 
        obj['id'] = id;
        obj['is_active'] = isActive;
        obj['role'] = role;
        obj['user_id'] = userId;
        obj['username'] = username;
        obj['email'] = email;
    }

    /**
     * Constructs a <code>CollaboratorOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CollaboratorOut} obj Optional instance to populate.
     * @return {module:model/CollaboratorOut} The populated <code>CollaboratorOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CollaboratorOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('is_active')) {
                obj['is_active'] = ApiClient.convertToType(data['is_active'], 'Boolean');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = CollaboratorRole.constructFromObject(data['role']);
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('picture')) {
                obj['picture'] = ApiClient.convertToType(data['picture'], 'String');
            }
            if (data.hasOwnProperty('picture_url')) {
                obj['picture_url'] = ApiClient.convertToType(data['picture_url'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CollaboratorOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CollaboratorOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CollaboratorOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
            throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
        }
        // ensure the json data is a string
        if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
            throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
        }
        // ensure the json data is a string
        if (data['picture'] && !(typeof data['picture'] === 'string' || data['picture'] instanceof String)) {
            throw new Error("Expected the field `picture` to be a primitive type in the JSON string but got " + data['picture']);
        }
        // ensure the json data is a string
        if (data['picture_url'] && !(typeof data['picture_url'] === 'string' || data['picture_url'] instanceof String)) {
            throw new Error("Expected the field `picture_url` to be a primitive type in the JSON string but got " + data['picture_url']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }

        return true;
    }


}

CollaboratorOut.RequiredProperties = ["id", "is_active", "role", "user_id", "username", "email"];

/**
 * @member {String} id
 */
CollaboratorOut.prototype['id'] = undefined;

/**
 * @member {Boolean} is_active
 */
CollaboratorOut.prototype['is_active'] = undefined;

/**
 * @member {module:model/CollaboratorRole} role
 */
CollaboratorOut.prototype['role'] = undefined;

/**
 * @member {String} user_id
 */
CollaboratorOut.prototype['user_id'] = undefined;

/**
 * @member {String} username
 */
CollaboratorOut.prototype['username'] = undefined;

/**
 * @member {String} picture
 */
CollaboratorOut.prototype['picture'] = undefined;

/**
 * @member {String} picture_url
 */
CollaboratorOut.prototype['picture_url'] = undefined;

/**
 * @member {String} email
 */
CollaboratorOut.prototype['email'] = undefined;






export default CollaboratorOut;

