/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ExperimentStatus from './ExperimentStatus';
import ExtendedAttachedExperimentDatasetVersionOut from './ExtendedAttachedExperimentDatasetVersionOut';
import PicturedUserOut from './PicturedUserOut';

/**
 * The ExperimentOut model module.
 * @module model/ExperimentOut
 * @version 1.0.0
 */
class ExperimentOut {
    /**
     * Constructs a new <code>ExperimentOut</code>.
     * @alias module:model/ExperimentOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param id {String} 
     * @param name {String} 
     * @param description {String} 
     * @param baseExperimentId {String} 
     * @param baseModelVersionId {String} 
     * @param attachedDatasets {Array.<module:model/ExtendedAttachedExperimentDatasetVersionOut>} 
     * @param status {module:model/ExperimentStatus} 
     */
    constructor(createdAt, updatedAt, createdBy, id, name, description, baseExperimentId, baseModelVersionId, attachedDatasets, status) { 
        
        ExperimentOut.initialize(this, createdAt, updatedAt, createdBy, id, name, description, baseExperimentId, baseModelVersionId, attachedDatasets, status);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, id, name, description, baseExperimentId, baseModelVersionId, attachedDatasets, status) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['id'] = id;
        obj['name'] = name;
        obj['description'] = description;
        obj['base_experiment_id'] = baseExperimentId;
        obj['base_model_version_id'] = baseModelVersionId;
        obj['attached_datasets'] = attachedDatasets;
        obj['status'] = status;
    }

    /**
     * Constructs a <code>ExperimentOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExperimentOut} obj Optional instance to populate.
     * @return {module:model/ExperimentOut} The populated <code>ExperimentOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExperimentOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('base_experiment_id')) {
                obj['base_experiment_id'] = ApiClient.convertToType(data['base_experiment_id'], 'String');
            }
            if (data.hasOwnProperty('base_model_version_id')) {
                obj['base_model_version_id'] = ApiClient.convertToType(data['base_model_version_id'], 'String');
            }
            if (data.hasOwnProperty('attached_datasets')) {
                obj['attached_datasets'] = ApiClient.convertToType(data['attached_datasets'], [ExtendedAttachedExperimentDatasetVersionOut]);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ExperimentStatus.constructFromObject(data['status']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExperimentOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExperimentOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExperimentOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['base_experiment_id'] && !(typeof data['base_experiment_id'] === 'string' || data['base_experiment_id'] instanceof String)) {
            throw new Error("Expected the field `base_experiment_id` to be a primitive type in the JSON string but got " + data['base_experiment_id']);
        }
        // ensure the json data is a string
        if (data['base_model_version_id'] && !(typeof data['base_model_version_id'] === 'string' || data['base_model_version_id'] instanceof String)) {
            throw new Error("Expected the field `base_model_version_id` to be a primitive type in the JSON string but got " + data['base_model_version_id']);
        }
        if (data['attached_datasets']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['attached_datasets'])) {
                throw new Error("Expected the field `attached_datasets` to be an array in the JSON data but got " + data['attached_datasets']);
            }
            // validate the optional field `attached_datasets` (array)
            for (const item of data['attached_datasets']) {
                ExtendedAttachedExperimentDatasetVersionOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExperimentOut.RequiredProperties = ["created_at", "updated_at", "created_by", "id", "name", "description", "base_experiment_id", "base_model_version_id", "attached_datasets", "status"];

/**
 * @member {Date} created_at
 */
ExperimentOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExperimentOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ExperimentOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExperimentOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ExperimentOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ExperimentOut.prototype['description'] = undefined;

/**
 * @member {String} base_experiment_id
 */
ExperimentOut.prototype['base_experiment_id'] = undefined;

/**
 * @member {String} base_model_version_id
 */
ExperimentOut.prototype['base_model_version_id'] = undefined;

/**
 * @member {Array.<module:model/ExtendedAttachedExperimentDatasetVersionOut>} attached_datasets
 */
ExperimentOut.prototype['attached_datasets'] = undefined;

/**
 * @member {module:model/ExperimentStatus} status
 */
ExperimentOut.prototype['status'] = undefined;






export default ExperimentOut;

